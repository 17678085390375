@import "config";

.ngx-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 1em 0 4em !important;
    padding: 0;

    @media #{$medium-up} {
        padding: 0 $body-padding-medium-up;
    }

    li {
        font-size: rem(18px);
        margin: 0 .15em;

        &.current {
            background: $primary;
            padding: 0; // 0.056em 0.47em;
            border-radius: 3px;
        }

        &.pagination-previous {
            &.disabled,
            a {
                &::before {
                    content: '‹' !important;
                    font-size: 1.5em;
                }
            }
        }

        &.pagination-next {
            &.disabled,
            a {
                &::after {
                    content: '›' !important;
                    font-size: 1.5em;
                }
            }
        }

        > div,
        > a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 27px;
            height: 27px;
            padding: 0;
        }
    }
}