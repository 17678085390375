@import "colors";
@import "typography";

.mat-mdc-form-field-required-marker {
    color: $ups-warning-red;
}

.mat-mdc-form-field-error,
.mat-mdc-form-field-hint {
    font-family: $body-font-family;
    font-size: .9em;
    font-weight: 400;
    line-height: 1.5;

    &:last-of-type {
        margin-bottom: 1em;
    }

    span {
        display: inline-flex;
        column-gap: 0.25em;
    }
}

.mat-mdc-form-field {
    .mdc-text-field {
        background-color: white;
    }

    &.label--float-top {
        .mat-mdc-text-field-wrapper {
            &.mdc-text-field--outlined {
                .mdc-notched-outline--upgraded {
                    .mdc-notched-outline__notch {
                        border-top: solid thin rgba(0, 0, 0, .38);
                    }

                    .mdc-floating-label--float-above {
                        max-width: max-content;
                        transform: translateY(-37.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
                    }
                }
            }
        }
    }
}

.mat-mdc-slider {
    --mdc-slider-handle-width: 1.5rem;
    --mdc-slider-handle-height: 1.5rem;
    --mdc-slider-active-track-height: 3px;
    --mdc-slider-inactive-track-height: 3px;
    margin: 0 12px !important;
    max-width: 94%;

    .mdc-slider__thumb {
        .mdc-slider__thumb-knob {
            border-width: .35rem;
            border-color: white;
        }
    }
}

.mdc-button__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 0.25em;
}

.mat-mdc-form-field-type-mat-date-range-input {
    .mat-mdc-form-field-error-wrapper {
        transform: translateY(-35%) !important;
    }
}

.mdc-data-table__header-cell {
    &.text-center {
        text-align: center;
    }

    &.text-left {
        text-align: left;
    }

    &.text-right {
        text-align: right;
    }
}

.mat-mdc-form-field {
    &.compact {
        font-size: 14px;

        .mat-mdc-text-field-wrapper {
            &.mdc-text-field--outlined {
                .mat-mdc-form-field-infix {
                    min-height: 37px;
                    max-height: 37px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
            }
        }
    }
}

[mat-stroked-button]:not(:disabled) {
    &[color="primary"] {
        border-color: $primary;
    }
}

.mat-mdc-tab-group {
    &.ppi-tab-group {
        font-size: .875em;

        .mat-mdc-tab-header {
            border-bottom: solid 1.5px $ups-gray-4;

            .mat-mdc-tab-labels {
                column-gap: 0.2em;

                .mdc-tab {
                    border: solid 1.5px $ups-gray-4;
                    border-bottom: none;
                    padding: .65em 1.12em;
                    border-radius: .5em .5em 0 0;
                    height: auto;

                    &.mdc-tab--active {
                        --mdc-tab-indicator-active-indicator-color: transparent;
                        --mat-tab-header-active-focus-indicator-color: transparent;
                        --mat-tab-header-active-hover-indicator-color: transparent;
                        border: solid 2.5px $ups-blue-ppi;
                        border-bottom: none;
                        background-color: $ups-blue-ppi-lighter;
                    }

                    sup {
                        line-height: 0;
                    }
                }
            }
        }
    }
}
