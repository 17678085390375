/* Thin */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Bold */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Black */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('/assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('/assets/fonts/Roboto_Flex/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf') format('truetype');
  font-weight: 100 1000; /* Range of weight from 100 to 1000 */
  font-stretch: 25% 151%; /* Range of width from 25% to 151% */
  font-style: oblique -10deg 0deg; /* Range of slant from -10 degrees to 0 degrees */
}

/* Open Sans - Normal Styles */
@font-face {
  font-family: 'Open Sans';
  src: local('Roboto'), url('/assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Range covers weights from Thin (100) to Black (900) */
  font-style: normal;
  font-stretch: normal;
}

/* Open Sans - Italic Styles */
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url('/assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Range covers weights from Thin (100) to Black (900) */
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 100 900;  /* This range covers weights from Thin (100) to Black (900) */
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  src: url('/assets/fonts/Material_Symbols/MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].woff2') format('woff2'),
  url('/assets/fonts/Material_Symbols/MaterialSymbolsOutlined[FILL,GRAD,opsz,wght].ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Symbols Rounded';
  src: url('/assets/fonts/Material_Symbols/MaterialSymbolsRounded[FILL,GRAD,opsz,wght].woff2') format('woff2'),
  url('/assets/fonts/Material_Symbols/MaterialSymbolsRounded[FILL,GRAD,opsz,wght].ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Symbols Sharp';
  src: url('/assets/fonts/Material_Symbols/MaterialSymbolsSharp[FILL,GRAD,opsz,wght].woff2') format('woff2'),
  url('/assets/fonts/Material_Symbols/MaterialSymbolsSharp[FILL,GRAD,opsz,wght].ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
