.overlay {
    .mat-mdc-input-element {
        color: white;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label {
        color: rgba(255, 255, 255, .54);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
        background-color: rgba(255, 255, 255, .54) !important;
    }

    .mat-icon {
        color: white;
    }
}