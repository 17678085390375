@import "config";

.ups-form {
    --mat-form-field-subscript-text-size: 12px;

    background-color: white;
    font-size: 1rem;

    .form-section-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        margin-bottom: .5rem;

        @media #{$medium-up} {
            flex-direction: row;
            align-items: center;
        }

        .form-section-title {
            @include flex-row(center);
            font-family: $header-font-family;
            color: $color-text-header;
            font-size: em(18px);
            font-weight: 600;
            white-space: pre-wrap;

            > img {
                margin: 0 .25em;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .form-section-actions {
            margin: 1em auto 1.5em 0;
        }
    }

    > .form-body {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        justify-content: flex-start;
        background-color: white;
        padding: em(24px);
        @extend .fill-height;

        .form-body {
            @media #{$medium-up} {
                padding: 0;
            }
        }

        .form-sections {
            display: flex;
            flex-direction: column;
            align-content: stretch;
            justify-content: flex-start;
        }

        .form-section {
            display: flex;
            flex-direction: column;
            align-content: stretch;
            justify-content: flex-start;
            margin-bottom: 2em;
        }

        .form-options {
            .form-option-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        .mat-mdc-select {
            width: 100%;

            + [mdSuffix] {
                margin-left: 1em;
            }
        }

        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field,
        .mat-form-field-label {
            font-size: em(14px);

            &:not(.date-input) {
                width: 100%;
            }

            input {
                font-size: 1em;
            }

            label {
                font-size: 1em;
            }
        }

        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            height: 1px;
            background-color: $ups-stone;
        }

        /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-value-text {
            //font-size: .9em;
        }

        .mat-mdc-input-element {
            //font-size: .9em;

            &[type=date] {
                //font-size: .8em;
            }
        }

        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-input:checked {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            ~ .mat-checkbox-background {
                background-color: $accent;
            }
        }

        .mat-mdc-checkbox {
            font-size: em(13px);
            align-self: flex-start;

            &.checkbox-right {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }

            + .checkbox-child-content {
                padding: 0 0 0 2.4em;
            }

            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-layout {
                margin-top: .5em;
                margin-bottom: 0;
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-inner-container {
                    width: 18px;
                    height: 18px;
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    .mat-checkbox-frame {
                        border-color: #cdcdcd;
                    }
                }
            }

            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-label {
                &:hover {
                    //font-weight: 600;
                }
            }
        }

        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {
            margin-top: rem(6px);
            margin-bottom: 0;
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle,
            .mat-radio-inner-circle {
                width: 18px;
                height: 18px;
            }

            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle {
                border-color: $color-checkbox-frame;
            }

            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
                font-size: em(13px);

                &:hover {
                    //font-weight: 600;
                }
            }
        }
    }

    > .form-footer {
        background-color: white;

        @media #{$medium-up} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        .form-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 2em;

            &:last-child {
                margin-bottom: 0;
            }

            @media #{$medium-up} {
                justify-content: flex-end;
                margin-left: auto;
                //padding: 2em;
            }

            .mat-mdc-raised-button {
                width: 45%;
                margin-top: 1rem;

                &:nth-child(odd) {
                    margin-right: 1rem;
                }

                &:nth-child(even) {
                    margin-left: 1rem;
                }

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                @media #{$medium-up} {
                    width: auto;
                    min-width: 10rem;
                    margin: 0;
                }
            }
        }
    }
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mat-mdc-form-field-error {
    font-size: .9em;
}

.select-wrapper {
    > .mat-mdc-form-field-error {
        margin-top: -1em;
    }
}

.mat-mdc-radio-button.mat-accent {
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    &.mat-radio-checked {
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-container {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle {
                border-color: $accent;
            }

            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-inner-circle {
                background-color: $accent;
            }
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-disabled {
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-outer-circle {
        border-color: rgba(0, 0, 0, 0.38) !important;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-inner-circle {
        background-color: rgba(0, 0, 0, 0.38) !important;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label {
    font-weight: normal;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout,
.mat-radio-button {
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-radio-label-content,
    .mat-checkbox-label {
        font-weight: normal;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
        margin-top: 2px;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label,
    .mat-radio-label-content {
        white-space: normal;
        word-wrap: break-word;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .mat-icon {
            margin-left: .5em;
        }
    }
}

.mat-mdc-checkbox {
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        align-items: center;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate,
.mat-checkbox-checked {
    &.mat-accent {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-layout {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-background {
                background-color: $primary;
            }
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-placeholder {
    &::after {
        color: $ups-error;
    }
}

.mat-placeholder-required {
    color: $ups-warning-red;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-should-float {
    .mat-placeholder-required {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        &.mat-form-field-required-marker {
            //color: rgba(0, 0, 0, 0.58);
            color: $ups-warning-red;
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-hint-wrapper {
    .mat-mdc-form-field-hint {
        color: $color-text-subscript;
        font-size: 0.54rem;
    }
}

.mat-focused {
    .mat-placeholder-required {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        &.mat-form-field-required-marker {
            color: $ups-warning-red;
        }
    }
}

.mat-mdc-raised-button {
    &.mat-primary {
        background-color: $accent;
    }
}

label {
    &.mat-mdc-button,
    &.mat-mdc-raised-button {
        &.disabled {
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.38);
            box-shadow: none;
            cursor: default;
        }
    }
}

.mat-mdc-tooltip {
    font-size: rem(10px) !important;
    font-weight: 400;
    line-height: 24px;
    text-overflow: ellipsis;
    letter-spacing: .5px;
    //max-width: 40em !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-labelwrapper,
.mat-form-field-label-wrapper,
.mat-form-field-appearance-legacy {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label,
    .mat-mdc-form-field-hint {
        color: $color-text-placeholder !important;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-should-float {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-labelwrapper,
    .mat-form-field-label-wrapper {
        overflow: visible !important;
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label,
        .mat-form-field-label {
            overflow: visible;
        }
    }
}

.mat-step-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.mat-datepicker-toggle {
    .mat-mdc-icon-button {
        position: relative;

        // Events are not fired for a visibility:hidden element.
        //visibility: hidden;

        &::after {
            content: url("../../assets/icons/calendar-symbol.svg");
            position: absolute;
            top: 3px;
            bottom: 0;
            left: 0;
            right: 0;
            visibility: visible;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}

.mat-mdc-option {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.date-input {
    .mat-mdc-input-element {
        visibility: hidden;
        height: 0;
    }

    .formatted-date {
        font-size: .9em;
        display: inline-block;
        width: 100%;
        position: absolute;
        top: .7em;
        left: 0;
    }
}

.mat-mdc-checkbox {
    &.ups-checkbox {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-layout {
            margin-top: .5em;
            margin-bottom: 0;
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-inner-container {
                width: 1.25rem;
                height: 1.25rem;
                margin-top: 2px;
                margin-right: 1rem;
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-frame {
                    border-color: #cdcdcd;
                }
            }

            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-label {
                line-height: inherit;
            }
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-outlined-button:not(.mat-button-disabled) {
    &.ups-stroked-button {
        border-color: #0A8080;
        color: #0A8080;
        background-color: white;
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-wrapper {
            margin-top: -1px;
            margin-bottom: 1px;
        }
    }
}

.mat-mdc-radio-button,
.mat-mdc-checkbox {
    .mdc-label {
        font-family: $body-font-family;
        font-weight: 400;
        font-size: rem(14px);
        margin-bottom: 0;
    }
}

//.mat-mdc-radio-button {
//  .mat-mdc-form-field {
//    .mdc-label {
//      font-size: em(13px);
//      align-self: flex-start;
//    }
//  }
//}

.mat-mdc-form-field-type-mat-input {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
        & > .mat-icon {
            padding: 10px;
        }
    }

    .mat-mdc-form-field-icon-suffix {
        padding: 0 8px 0 4px;
    }
}

.button-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

span[matsuffix] {
    padding: 0 .5em;
}

.mdc-button {
    span[matsuffix] {
        padding: unset;
        margin-right: -0.5em;
    }
}

.mat-mdc-form-field {
    &.input-with-button {
        .mat-mdc-notch-piece {
            &.mdc-notched-outline__trailing {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        + button {
            --mdc-filled-button-container-height: 40px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.mat-mdc-radio-group {
    &[required] {
        mat-label {
            align-self: flex-start;
            position: relative;

            &::after {
                content: "*";
                position: absolute;
                top: 0;
                right: -.65em;
                color: $ups-error;
            }
        }
    }
}

.mat-mdc-form-field.textarea-field {
    @include flex-column(stretch);
    flex: 1;
    min-height: 8em;

    .mat-mdc-text-field-wrapper {
        @include flex-column(stretch);
        flex: 1;
        height: 100%;
        width: 100%;

        .mat-mdc-form-field-flex {
            @include flex-column(stretch);
            flex: 1;
            height: 100%;
            width: 100%;

            .mat-mdc-form-field-infix {
                @include flex-column(stretch);
                flex: 1;
                height: 100%;
                width: 100%;

                textarea {
                    flex: 1;
                    resize: none;
                }
            }
        }
    }
}
