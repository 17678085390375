@import "config";

// === Toastr =================================================

#toast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;

    .toast {
        width: 100% !important;
        border-radius: 0;
        flex-basis: unset; //MV3-4381
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // MV3-4381 - IE Fallback
            flex-basis: auto;
       }
    }
}

.toast-top-right {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 0 !important;
    right: 0 !important;

    @media #{$medium-up} {
        top: -1rem !important;
        right: 1.5rem !important;
    }
}

.toast {
    display: flex !important; // MV3-4381 - IE Fallback (important)
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: space-between;
    position: relative;
    max-width: 100%;

    .toast-close-button {
        position: absolute;
        top: .15rem !important;
        right: .5rem !important;

        @media #{$medium-up} {
            top: .35rem;
            right: .75rem;
        }
    }
}

.toast-message {
    a {
        text-decoration: underline;
        color: $ups-gold !important;
    }
}

#toast-container > div {
    opacity:1;
}
