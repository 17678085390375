/**
system-native fonts designed for high legibility and performance:
    -apple-system for macOS and iOS,
    BlinkMacSystemFont for Chrome on macOS,
    Segoe UI for Windows
 */
$base-font-size-px: 16;
$base-font-size: #{$base-font-size-px}px;
$body-font-family: "Roboto Flex", sans-serif;
$body-font-family-system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto Flex", "Helvetica Neue", Arial, sans-serif;
$header-font-family: "Roboto Flex", sans-serif;
$header-font-family-alternative: "Open Sans", sans-serif;

$browser-context: $base-font-size-px;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context * 1em);
}

@function rem($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context * 1rem);
}
