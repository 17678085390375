@import "config";

/**
This CSS class is being deprecated. Use KeyValueComponent instead.
 */
.deprecated-key-values {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1em;

    @media #{$medium-up} {
        width: 25em;
    }

    .key-values-title {
        font-family: $header-font-family;
        font-weight: 700;
    }

    .key-value {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        padding: .5em 0;
        //white-space: pre;
        width: 25em;

        @media #{$medium-up} {
            justify-content: flex-start;
        }

        &.header {
            font-weight: 700;
            border-bottom: solid thin $ups-black-4;
            margin-bottom: 1em;
        }

        .key,
        .value {
            width: 49%;

            @media #{$medium-up} {
                width: 12em;
            }
        }

        .value {
            font-weight: 700;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            @media #{$medium-up} {
                justify-content: flex-start;
            }

            .mat-mdc-form-field {
                width: auto;
                font-size: 1em;
            }
        }
    }
}

/**
Define global styles for key and value CSS classes in KeyValueComponent here.
 */
.upsc-key-value {
    .key,
    .value {
        &.bold {
            font-weight: 600;
        }

        &.subtle {
            color: $ups-gray-4;
        }

        &.disabled {
            color: $upsc-gray-3;
            font-weight: 300;
        }

        &.clickable {
            text-decoration: underline;
            cursor: pointer;
            color: $ups-blue-ppi;
        }
    }
}
