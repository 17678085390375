@import "config";

table.ups-table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 0 5px 0 rgba(0, 0, 0, 0.14);

    thead,
    tbody,
    tfoot {
        tr {
            th,
            td {
                padding: 1rem;
                text-align: left;
                vertical-align: middle;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                white-space: pre;

                &.text-center {
                    text-align: center;
                }

                &.text-left {
                    text-align: left;
                }

                &.text-right {
                    text-align: right;
                }

                &.sticky {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }

                .mat-mdc-checkbox {
                    display: inline-flex;
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    .mat-checkbox-layout {
                        margin: 0;
                        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        .mat-checkbox-inner-container {
                            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            .mat-checkbox-frame {
                                border-color: $ups-gray-1;
                                background-color: white;
                            }
                        }
                    }
                }

                a {
                    color: $ups-blue-alternate;
                }
            }
        }
    }

    thead {
        tr {
            border-bottom: solid 2px $ups-gray-4;

            th {
                background-color: $ups-gray-1;
                color: $ups-teal-light;
                white-space: nowrap;

                &:not(:last-of-type) {
                    border-right: solid 1px $ups-gray-5;
                }

                &:first-of-type {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &:last-of-type {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                .mat-mdc-checkbox {
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    .mat-checkbox-layout {
                        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        .mat-checkbox-inner-container {
                            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            .mat-checkbox-frame {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            border-bottom: solid 1px $ups-gray-4;

            td {
                background-color: white;
            }
        }
    }

    tfoot {
        tr {
            td {

            }
        }
    }
}

.upsc-table {
    thead {
        display: none;
    }

    tbody {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        @media #{$medium-up} {
            display: initial;
        }

        tr {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;

            @media #{$medium-up} {
                display: initial;
            }

            td {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                &:not(:first-of-type) {
                    border-top-width: 0;
                }

                .column-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 1em;
                    font-weight: 700;

                    @media #{$medium-up} {
                        display: none;
                    }

                    &::after {
                        content: ':';
                    }
                }
            }
        }
    }
}

.mat-mdc-table {
    display: table !important;
    width: 100%;

    > .mat-mdc-header-row, > .mat-mdc-row {
        display: table-row;
        padding: 0;
        border: none;

        > .mat-mdc-header-cell, > .mat-mdc-cell {
            display: table-cell;
            height: 48px;
            vertical-align: middle;

            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    .mat-mdc-header-cell {
        color: $color-text-placeholder !important;
    }
}

.dataTables_wrapper {
    width: 100%;
    overflow: auto;
    font-size: 1rem;

    @media #{$large-up} {
        padding: 0 em(40px);
    }

    table.dataTable {
        border: solid thin $ups-background-color;

        &.no-footer {
            border-bottom: solid thin $ups-background-color;
        }

        &.stripe {
            thead tr,
            tbody tr {
                height: em(50px);

                th,
                td {
                    white-space: nowrap;
                }
            }

            thead {
                background-color: $ups-stone;

                tr th {
                    font-size: em(15px);
                    color: $ups-bray;
                    border-bottom: none;
                }
            }

            tbody {
                border-bottom: none;

                tr:nth-child(odd) {
                    background-color: white;
                }

                tr:nth-child(even) {
                    background-color: $ups-background-color;
                }

                tr {

                    td {
                        font-size: rem(13px);
                        color: $color-text-table-content;

                        .actions {
                            display: inline-flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            flex-wrap: nowrap;

                            .mat-mdc-raised-button {
                                line-height: 2.35em;
                                height: rem(30px);
                                margin: 0 .5231em;
                            }
                        }
                    }
                }
            }
        }

        &.compact {
            thead tr {
                height: em(28px);
                background-color: $color-background;

                th {
                    font-size: em(11px);
                    color: $ups-bray;
                    border-bottom: none;
                }
            }

            tbody tr {
                height: em(38.3px);

                td {
                    font-size: em(13px);
                    color: $color-text-header;
                }
            }
        }

        th,
        td {
            visibility: visible;
            opacity: 1;
            transition: visibility .3s ease, opacity .3s ease;

            &[hidden] {
                display: table-cell;
                visibility: collapse;
                opacity: 0;
                max-width: 0;
                padding: 0;
            }

            &.dataTables_empty {
                //display: none;
            }
        }

        tbody {
            tr:not(:first-child) {
                .dataTables_empty {
                    display: none;
                }
            }
        }
    }
}

.responsive-table {
    width: 100% !important;
    position: relative;

    .dataTables_wrapper {
        overflow-x: hidden;

        table.dataTable > tbody > tr.child ul.dtr-details {
            width: 100%;
        }

        table.dataTable > tbody > tr.child span.dtr-title {
            min-width: rem(120px);
        }

        table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
        table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
            top: -1px;
            left: 0;
            color: $text-default;
            border: none;
            background-color: transparent;
            font-size: 2em;
            box-shadow: none;
            position: relative;
        }

        .mat-mdc-button.actions {
            padding: 0;
            min-width: 0;
            width: 3em;
            height: 3em;
            border-radius: 50%;
        }

        .row-actions {
            .buttons-menu {
                display: flex;
                text-align: center;

                @media #{$large-up} {
                    display: none;
                }

                &.always {
                    @media #{$large-up} {
                        display: flex;
                    }
                }
            }

            .buttons-explicit {
                display: none;

                @media #{$large-up} {
                    display: flex;
                    flex-wrap: wrap;
                }

                &.never {
                    @media #{$large-up} {
                        display: none;
                    }
                }
            }
        }

        .action-item.mat-mdc-raised-button {
            padding: 0 1em;
            margin: .25em .5em;
            min-width: initial;
            line-height: 2.5em;
            /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            .mat-button-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: .8em;

                span {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 .25em;

                    &.icon {
                        .mat-icon {
                            font-size: 1.75em;
                            height: 20px;
                            width: 20px;
                            line-height: 1.1;
                        }
                    }

                    &.text {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

.mat-mdc-no-data-row {
    .mat-mdc-cell {
        font-weight: 300;
        color: $ups-gray-3;
        text-align: center;
    }
}

table.mat-mdc-table.ppi-table {
    border-collapse: collapse;
    border-spacing: 0;

    th {
        --border-color: #{$upsc-gray-1};
        border-top: none;
        border-bottom: none;

        &:first-of-type {
            border-left: solid thin var(--border-color);
        }

        &:last-of-type {
            border-right: solid thin var(--border-color);
        }

        &:not(:last-of-type) {
            border-right: solid thin white;
        }

        &.no-border {
            border-right: solid thin var(--border-color);
        }
    }

    td {
        border-top: none;
        border-bottom: none;
        border-left: solid thin white;
        border-right: solid thin white;
    }

    thead {
        th.mat-mdc-header-cell {
            color: white !important;
            font-size: .8205em;
            font-weight: 400;
            line-height: 1.23069;
            background-color: $upsc-gray-1;

            --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
            --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
            --mdc-checkbox-selected-checkmark-color: white;
            --mdc-checkbox-selected-focus-icon-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-selected-hover-icon-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-selected-icon-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-unselected-focus-icon-color: #DEDEDE;
            --mdc-checkbox-unselected-hover-icon-color: #DEDEDE;
            --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
            --mdc-checkbox-selected-focus-state-layer-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-selected-hover-state-layer-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-selected-pressed-state-layer-color: #{$ups-blue-ppi-medium};
            --mdc-checkbox-unselected-focus-state-layer-color: white;
            --mdc-checkbox-unselected-hover-state-layer-color: white;
            --mdc-checkbox-unselected-pressed-state-layer-color: white;
            --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.38);
            --mat-checkbox-label-text-color: rgba(255, 255, 255, 0.87);

            .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
            .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
                border-color: white;
                background-color: transparent;
            }
        }
    }

    .mat-mdc-row {
        --mat-table-row-item-label-text-size: .8rem;
    }

    .mat-mdc-header-cell, .mat-mdc-footer-cell, .mat-mdc-cell {
        box-sizing: border-box;
    }
}
