@import "config";

.ups-page-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    font-size: 1rem;
    //background-color: $ups-stone-lighter;
    //margin-left: -1000px;
    //margin-right: -1000px;
    //padding: 2rem calc(1000px + 2em);
    //overflow: hidden;
    //border-bottom: solid 2px $accent;
    padding: 1.5rem 2rem;
    background-color: white;

    @media #{$medium-up} {
        flex-direction: row;
        align-items: center;
        padding: em(42px) em(32px);
        min-width: 40.9375em;
    }

    @media #{$large-up} {
        min-width: 70.9375em;
    }

    @media #{$ups-laptop} {
        min-width: 25rem;
    }

    .page-title {
        font-size: em(20px);
        font-weight: 600;
        color: $color-text-header;
        margin: 0;
        white-space: nowrap;

        @media #{$medium-up} {
            font-size: em(25px);
        }
    }

    .page-actions,
    [page-actions] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 1rem 0;

        @media #{$medium-up} {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-left: auto;
            padding: 0;
        }

        .page-action {
            //width: 46%;
            //min-width: 10rem;
            margin-bottom: 1em;

            &:not(:first-child) {
                @media #{$medium-up} {
                    margin-left: 1rem;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @media #{$medium-up} {
                width: auto;
                margin-bottom: 0;
            }
        }
    }
}
