@import "config";

.buorg {
    background-color: $color-notification-background !important;
    padding: rem(18px);
    border-bottom: none !important;
    box-shadow: none !important;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        font-family: $body-font-family;

        .buorg-icon {
            zoom: 1.25;
        }

        .buorg-mainmsg,
        .buorg-moremsg {
            font-size: rem(15px);
            letter-spacing: .5px;
            color: $color-text-table-content;
        }

        .buorg-mainmsg {
            margin: 0 .5rem;
        }

        .buorg-buttons {
            padding: 0 rem(34px);

            a {
                box-shadow: none;
                padding: rem(4px) rem(26px);
                color: white;
                font-size: 1rem;
                letter-spacing: .6px;
                border: solid thin $primary;
            }

            #buorgul {
                background-color: $primary;
            }

            #buorgig {
                visibility: hidden;
                background-color: transparent;
                color: $primary;

                &::after {
                    content: "\E5CD";
                    font-family: "Material Icons";
                    font-size: rem(20px);
                    color: $color-text-table-content;
                    position: fixed;
                    top: rem(10px);
                    right: rem(10px);
                    visibility: visible;
                }
            }
        }
    }
}