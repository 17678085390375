.input-field {
    position: relative;
    margin: 1rem 0 3rem 0;

    input,
    select {
        width: 100%;
        height: 3rem;

        + label {
            position: absolute;
            top: -2rem;
            left: 0;
        }

        &[required] {
            + label {
                &::after {
                    content: "*";
                    color: red;
                    margin-left: .25em;
                }
            }
        }
    }
}
