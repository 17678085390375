@import "media-queries";
@import "mixins";

.content-actions {
    @include flex-column;

    @media #{$medium-up} {
        @include flex-row(center);
        justify-content: space-between;
    }

    .left-actions,
    .right-actions {
        @include flex-column;

        @media #{$medium-up} {
            @include flex-row(center);
        }
    }

    .left-actions {
        @media #{$medium-up} {
            justify-content: flex-start;
        }

        .action {
            @media #{$medium-up} {
                margin-right: 1em;
            }
        }
    }

    .right-actions {
        @media #{$medium-up} {
            justify-content: flex-end;
        }

        .action {
            @media #{$medium-up} {
                margin-left: 1em;
            }
        }
    }

    input.shipment-history-date-range {
        font-size: em(14px);
    }
}
