@import "config";

.mat-mdc-dialog-container {
    padding: 0 !important;

    @media #{$medium-up} {
        max-height: 80vh !important;
    }
}

.mobile-fullscreen-dialog {
    @media #{$mobile} {
        width: 100vw !important;
        max-width: 100vw !important;
        height: 100vh !important;
        margin: 0;
        border-radius: 0;

        .mat-mdc-dialog-container {
            .mdc-dialog__surface {
                border-radius: 0;
            }
        }
    }
}

.ups-dialog {
    font-size: 1rem;

    .notifications {
        font-size: em(13px);
        margin-bottom: 1em;
    }

    .mat-mdc-dialog-title {
        &.mdc-dialog__title {
            padding: 0 0 9px 0;
            font-size: 1.5rem;
        }
    }

    .dialog-header {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        background-color: $ups-stone-lighter;
        //margin: -2em -2em 0 -2em;
        padding: .725em 2em;
        overflow: hidden;
        position: relative; // fallback for IE.
        position: sticky;
        top: 0;
        z-index: 9;

        @media #{$medium-up} {
            flex-direction: row;
            align-items: center;
        }

        &.clear-header {
            background-color: transparent;
            padding-top: 2em;

            .mat-mdc-dialog-title {
                font-size: 1.5em;
                font-weight: 700;
                color: $color-text-header;
            }

            .close-button {
                right: 1em;
            }
        }

        $closeButtonSize: rem(18px);

        .close-button {
            position: absolute;
            top: .25em;
            right: .5em;
            background: none;
            outline: none;
            border: none;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                //top: $closeButtonSize;
                bottom: rem(6px);
            }

            .material-icons {
                font-size: $closeButtonSize;
                width: $closeButtonSize;
                height: $closeButtonSize;
            }

            &.cdk-focused.cdk-keyboard-focused {
                border: none;
                outline: none;
                background: none;
            }
        }

        .close-button-medium {
            display: none;

            @media #{$medium-up} {
                display: inline-block;
            }
        }

        .mat-mdc-dialog-title {
            font-family: $header-font-family;
            font-size: em(20px);
            font-weight: 500;
            color: $ups-bray;
            margin: 0;
            //padding: 0 0 0.5em 0.75em;
        }

        .mat-mdc-dialog-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            @media #{$medium-up} {
                margin-left: auto;
                margin-right: 3em;
                margin-bottom: -6px;
                padding-top: 5px;
            }

            .mat-mdc-button,
            .mat-mdc-raised-button,
            .btn {
                width: 45%;
                margin-top: 1rem;

                &:nth-child(odd) {
                    margin-right: 1rem;

                    @media #{$medium-up} {
                        margin-right: 0;
                    }
                }

                &:nth-child(even) {
                    margin-left: 1rem;
                }

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                    margin-left: auto;

                    @media #{$medium-up} {
                        margin-left: 1rem;
                    }
                }

                @media #{$medium-up} {
                    width: auto;
                    min-width: 10rem;
                    margin: -1em 0 0;
                }
            }
        }
    }

    @supports (-ms-ime-align:auto) { // Only for Edge
        .dialog-header {
            position: relative;
        }
    }

    .mat-mdc-dialog-content {
        padding: 0 1.5em;
        margin: 0;
        min-height: 5em;
        max-height: 9999px; // fallback for IE.
        max-height: initial;

        @media #{$medium-up} {
            padding: 0 2em;
            //max-height: 65vh;
        }

        .form-body {
            padding: 1.5em 0;
        }
    }

    .mat-mdc-dialog-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        //margin-bottom: -12px !important;

        @media #{$medium-up} {
            justify-content: flex-end;
        }
    }

    &.alert {
        border: 0;
        border-top: solid 5px $ups-warning-red;
        padding: em(32px) em(60px);
        margin: 0;

        .dialog-header {
            background: none;
            margin-bottom: em(16px);
            padding: 0;

            .mat-mdc-dialog-title {
                img.title-icon {
                    width: rem(25px);
                    height: auto;
                }

                .title-message {
                    margin: 0 .5em;
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: 0;

            .dialog-message {
                font-size: em(14px);
                padding: 1em 0;
                line-height: 1.71;
            }
        }

        .mat-mdc-dialog-actions {
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            .mat-mdc-raised-button {
                font-size: em(14px);

                &.transparent-button {
                    padding: 0;

                    @media #{$medium-up} {
                        padding: 0 em(32px);
                    }
                }
            }
        }
    }
}

.ppi-dialog {
    @include flex-column(stretch);
    row-gap: 1em;
    padding: 2.5em 1.5em 1.5em;

    .dialog__header {
        .mat-mdc-dialog-title {
            font-size: 2em;
            font-weight: 400;
            line-height: 1;
            color: $ups-black;
            --header-underline-gap: .85rem;
            position: relative;
            padding: 0 0 var(--header-underline-gap);

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                max-width: 4.5rem;
                height: .25rem;
                background-color: $primary;
            }
        }

        .dialog__close-button {
            position: absolute;
            top: .5em;
            right: .5em;

            .mat-icon,
            [class^="material-icons-"] {
                font-size: .875em;
            }
        }
    }

    .dialog__content {
        @include flex-column(stretch);
        row-gap: 1em;
        font-size: 1em;
        color: $ups-black;

        p {
            margin: 0;
            padding: 0;
        }
    }

    .dialog__actions {
        @include flex-column(stretch);
        gap: 1em;

        @media #{$medium-up} {
            @include flex-row(stretch);
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        .dialog__action {
            border-radius: 9999px;
            padding: 0 1.5em;
            margin: 0;

            &.dialog__action--yes {
                .button__content {
                    &::after {
                        content: "chevron_right";
                        margin-right: -.5em;
                    }
                }
            }

            &.dialog__action--no {
                background-color: white;
                color: $primary;
                border: solid thin $primary;

                .button__content {
                    &::before {
                        content: "chevron_left";
                        margin-left: -.5em;
                    }
                }
            }

            .button__content {
                @include flex-row(center);
                justify-content: center;

                &::before,
                &::after {
                    content: "";
                    font-family: "Material Icons";
                    font-size: 1.5em;
                    font-weight: 400;
                }
            }
        }
    }
}

.mat-mdc-dialog-container {
    @media screen and (max-width: 32.5375em) {
        position: fixed;
        top: 0;
        left: 0
    }
}

.cdk-overlay-container {
    //-webkit-overflow-scrolling: touch;
    //-webkit-transform: translate3d(0, 0, 0);
}

.cdk-global-overlay-wrapper {
    //flex-direction: column;
}

.mat-mdc-dialog-component-host {
    position: relative;

    .mat-mdc-icon-button {
        &.close-icon {
            position: absolute;
            top: .5rem;
            right: .5rem;
        }
    }
}

.material-symbols-outlined.dialog-close-button {
    position: absolute;
    top: .5em;
    right: .5em;
    cursor: pointer;
    transform: scale(1);
    transform-origin: center;
    transition: transform .1s ease-in-out;
    font-variation-settings: "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
    color: $ups-icon !important;

    &:hover {
        transform: scale(1.1);
    }
}

.centered-dialog .mat-mdc-dialog-container {
    display: contents !important;
    align-items: center !important;
    justify-content: center !important;
    overflow: hidden;
    text-overflow: ellipsis;
}