@import "config";

.sidebar-footer-menu {
    font-size: 1rem;

    .mat-mdc-menu-item {
        text-decoration: none;
        font-size: em(10px);
        height: 2.5em;
        line-height: 2.5;

        &.emphasized {
            font-weight: 700;
            height: 3.5em;
            line-height: 3.5;
        }
    }
}

.mat-mdc-menu-item--ppi {
    min-height: 3.25em !important;

    @media #{$medium-up} {
        font-size: .8205em !important;
        min-height: 2.25em !important;
    }
}
