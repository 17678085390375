@import "config";
@import "font-face";
@import "browser-update-org";
@import "animations";
@import "button";
@import "card";
@import "dialog";
@import "form";
@import "key-value";
@import "menu";
@import "overlay";
@import "page-header";
@import "page-content";
@import "pagination";
@import "table";
@import "temp";
@import "text-styles";
@import "page-content-wrapper";
@import "mat-override";

/* open-sans-regular - latin */
//@font-face {
//    font-family: 'Open Sans';
//    font-style: normal;
//    font-weight: 400;
//    src: url('../fonts/open-sans-v28-latin-regular.eot'); /* IE9 Compat Modes */
//    src: local(''),
//         url('../fonts/open-sans-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         url('../fonts/open-sans-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//         url('../fonts/open-sans-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
//         url('../fonts/open-sans-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//         url('../fonts/open-sans-v28-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}

/* roboto-regular - latin */
//@font-face {
//    font-family: 'Roboto';
//    font-style: normal;
//    font-weight: 400;
//    src: url('../fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
//    src: local(''),
//         url('../fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         url('../fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//         url('../fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
//         url('../fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//         url('../fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
//}

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/material-icons-regular.woff2") format("woff2");
}

//@font-face {
//    font-family: 'Inter';
//    src: url('../fonts/Inter-Regular.ttf') format('woff2'),
//         url('../fonts/Inter-Regular.ttf') format('woff');
//  }

* {
    box-sizing: border-box;

    &:focus-visible {
        outline-color: #00857d;
    }
}

html, body, upsc-root {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
}

html {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    top: 0 !important;
    font-size: em(16px);
}

body {
    color: $color-text-default !important;
    font-size: 1em !important;
    font-weight: 500;
    font-family: $body-font-family;
    line-height: em(24px);
    //-webkit-overflow-scrolling: touch; // This makes floating labels go on top of other elements.
    //-webkit-transform: translate3d(0, 0, 0);
    @extend .fill-height;
    //scrollbar-gutter: stable;

    &.noscroll {
        @media #{$medium-down}{
            overflow: hidden;
            position: fixed;
        }
    }
}

upsc-root {
    display: block;
    //@extend .fill-height;

    @media #{$medium-up} {
        height: 100%;
    }
}

/* *** Material Classes *** */
// Fixes Material width resize problem in IE11
.cdk-overlay-pane {
    /** IE 11 fixes */
    .mat-mdc-select-panel {
        flex: 1 0 auto;
    }
}

.spinner-container {
    position: relative;
}

.modal-footer {
    border-top: 0px;
}

select.language {
    margin: 10px auto;
}

.grid-head {
    text-transform: uppercase;
    font-weight: bold;
    background-color: #ebebeb !important;
    font-size: 12px;
}

.table-striped > tbody > tr:nth-of-type(odd) .grid-rows {
    background-color: #f0f0ef !important;
    font-size: 12px;
}

/* color fonts */

.grey-font {
    color: #40c1ac !important;
}

.black-font {
    color: #573616 !important;
}

.white-font {
    color: #ffffff !important;
}

.orange-font {
    color: #ffb500 !important;
}

.font-5b {
    color: #5b5b5b !important;
}

.font-43 {
    color: #434343 !important;
}

.font-E6 {
    color: #e6e6e6 !important;
}

/* Search Box with  icon */

.stylish-input-group .input-group-addon {
    background: #ebebeb !important;
    border: 0;
}

.stylish-input-group .form-control {
    border-right: 0;
    box-shadow: 0 0 0;
    border-color: #ebebeb;
}

.stylish-input-group button {
    border: 0;
    background: transparent;
}

/* ---/End --Search Box with  icon-- */

/* ParcelPro custom color buttons */

.btn {
    border: none;
}

.btn-orange {
    background-color: #ffb500 !important;
    // font-size: 11px !important;
    color: #ffffff !important;
}

.btn-green {
    background-color: #40c1ac !important;
    // font-size: 11px !important;
    font-weight: bold;
}

.btn-grey {
    background-color: #cccccc !important;
    // font-size: 11px !important;
    font-weight: bold;
}

.btn-lightgrey {
    background-color: #f9f9f9 !important;
}

.btn-darkgrey {
    background-color: #008369 !important;
    color: #ffffff !important;
}

.btn-lightblue {
    background-color: #009cbd !important;
    color: #f4f4fc !important;
    font-weight: bold;
    border: 0px !important;
}

.btn-stone {
    background-color: $color-button-secondary !important;
    font-weight: bold;
}

.btn-sky {
    background-color: $ups-sky !important;
    font-weight: bold;
}

.btn-grey,
.btn-darkgrey,
.btn-green,
.btn-lightgrey,
.btn-lightblue,
.btn-stone,
.btn-sky {
    text-transform: uppercase;
    border-radius: 3px !important;
}

/* ParcelPro custom color buttons */

/* Circle Button */

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

/*.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}*/

.btn-greenbullet {
    width: 8px;
    height: 8px;
    /* text-align: center; */
    padding: 2px 0;
    /* font-size: 12px; */
    cursor: auto;
    /* line-height: 2; */
    border-radius: 5px;
    background-color: #72bb53 !important;
    margin-right: 3px;
}

.btn-redbullet {
    background-color: #ff5d55 !important;
}

.btn-bluebullet {
    background-color: #42b1fb !important;
}

.btn-yellowbullet {
    background-color: #ffb500 !important;
}

.btn-darkredbullet {
    background-color: #e61610 !important;
}

.btn-upsgrassbullet {
    background-color: #64a70b !important;
}

.btn-greybullet {
    background-color: #929292 !important;
}

.btn-bluebullet,
.btn-yellowbullet,
.btn-darkredbullet,
.btn-upsgrassbullet,
.btn-redbullet,
.btn-transparentbullet {
    width: 24px;
    height: 16px;
    padding: 2px 0;
    cursor: auto;
    border-radius: 5px;
    margin-right: 3px;
}

.btn-bulletpoint {
    width: 4px;
    height: 4px;
    padding: 1px 0;
    cursor: auto;
    /* border-radius: 2px; */
    margin-right: 8%;
    background-color: #573616;
}

/* Circle Button */

/* Date Range Picker */

.daterangepicker td.in-range {
    background-color: #ebf4c9 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #ffb500 !important;
}

.daterangepicker {
    background-color: #f0f0ef !important;
}

.daterangepicker .calendar-table {
    background-color: #f0f0ef !important;
}

/* Date Range Picker */

/* Header */

.header-text {
    color: #ffb500;
    font-size: 17px;
}

.header-text li a,
.header-text:hover {
    color: #ffb500;
}

.popup-header {
    background-color: #f0f0ef !important;
}

/* End /Header */

/* side bar Submenu */

.sidebar-submenu li {
    list-style: none;
    line-height: 30px !important;
}

.sidebar-submenu li.active a {
    color: #ffb500;
}

/* / end side bar submenu */

/* User Fields Dropdown */

.user-fields li {
    padding: 5px;
}

/* /end User Fields Dropdown */

/* Reports Dropdown Menu */

.reports-dropdown-menu > li > a:hover {
    background-image: none;
    background-color: #ffb500 !important;
}

/* vertical spaces */

.row-bottom-1 {
    margin-bottom: 1%;
}

.row-bottom-2 {
    margin-bottom: 2%;
}

.row-bottom-3 {
    margin-bottom: 3%;
}

.row-bottom-4 {
    margin-bottom: 4%;
}

/* Textbox */

.txtbox {
    border-top: 0px !important;
    box-shadow: none;
    border-left: 0px !important;
    border-radius: 0px;
    border-right: 0px !important;
    padding: 0px 0px !important;
    margin-left: 12px !important;
    font-size: 13px;
}

.txtbox-grey {
    background-color: #ebebeb;
}

/* Textbox */

/* Label */

.ctrl-label {
    padding: 0px 12px;
}

.text-normal {
    font-weight: normal;
}

.text-bold {
    font-weight: bold;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center !important;
}

/* display */

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.hide-me {
    display: none !important;
}

.hide {
    display: none !important;
}

/* Font sizes */

.text-smaller {
    font-size: 11px !important;
}

.text-small {
    font-size: 12px !important;
}

.text-extra-small {
    font-size: 10px !important;
}

.text-normalsize {
    font-size: 13px !important;
}

.text-big {
    font-size: 14px !important;
}

.text-bigger {
    font-size: 15px !important;
}

/* Radio Button */

input[type="radio"].blue-radio {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #2196f3;
    border-radius: 50%;
    outline: none;
    /*box-shadow:0 0 5px 0px gray inset;*/
}

input[type="radio"].blue-radio:hover {
    box-shadow: 0 0 2px 0px orange inset;
}

input[type="radio"].blue-radio:before {
    content: "";
    display: block;
    width: 58%;
    height: 58%;
    margin: 18% auto;
    border-radius: 50%;
}

input[type="radio"].blue-radio:checked:before {
    background: #2196f3;
}

#message {
    position: fixed;
    color: #ffffff;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e64d3c;
    z-index: 9999;
    text-align: center;
}

#inner-message {
    margin: 0 auto;
    padding: 11px;
    border: 1px solid transparent;
    /*border-radius: 4px;*/
}

.page-header2 {
    background-color: #f5f5f5;
    /*margin-top: -4%;*/
    padding-bottom: 5px;
    border-bottom: 2px solid #ffb500;
    color: $primary;
}

/* margin classes */

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1p {
    margin-bottom: 1% !important;
}

.mb-8p {
    margin-bottom: 8% !important;
}

.mt-8 {
    margin-top: 8px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-26 {
    margin-top: 26px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-12 {
    margin-left: 12px;
}

.mb-6 {
    margin-bottom: 6px;
}

.mt-1p {
    margin-top: 1%;
}

.ml-5p {
    margin-left: 5%;
}

/* /margin classes */

/* padding class */

.pt-5x {
    padding-top: 5px;
}

.pt-3x {
    padding-top: 3px;
}

.pl-2 {
    padding-left: 2px;
}

.pl-8 {
    padding-left: 8px;
}

.pl-12 {
    padding-left: 12px;
}

.clear-col-pad {
    padding: 0px 0px !important;
}

.pl-4p {
    padding-left: 4%;
}

.pl-5p {
    padding-left: 5%;
}

.pt-5p {
    padding-top: 5%;
}

.pt-2p {
    padding-top: 2%;
}

.pt-1p {
    padding-top: 1%;
}

/* /padding class */

/* Vertical Align Class */

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

/* /End - Vertical Align Class */

/* Border classes */

.black-borderb-1 {
    border-bottom: 1px solid #573616;
}

.border-0 {
    border: 0;
}

.border-bottom-1 {
    border-top: 0;
    border-bottom: 1px solid #eeeeee;
    border-left: 0;
    border-right: 0;
}

/* /Border classes */

/* Readonly Label */

.readonly-txtbox {
    background-color: transparent !important;
    border: 0;
}

/* End - Readonly Label */

/* Background */

.bg-verylightgrey {
    background-color: #fafafa;
}

/* End - Background */

#snackbar {
    min-width: 30%;
    background-color: #72bb53;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    position: fixed;
    z-index: 1;
    left: 40%;
    bottom: 0px;
    font-size: 13px;
    margin: 0 auto;
    text-align: left;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: right;
}

/* Width Classes */

.width85 {
    width: 85%;
}

.element-blocker-parent {
    position: relative;
}

/* Print */

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}

.mat-mdc-tooltip {
    //font-size: 10px !important;
}

.sidenav-tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -1em !important;
    padding: 0 7px !important;
    height: 20px !important;
    font-size: 9px !important;
    //background-color: rgba($ups-bray, .7) !important;
    color: white !important;
}

.dialog-center {
    text-align: center;
}

.zEWidget-launcher {
    z-index: 2 !important;
}

.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 16px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    .hamburger-box {
        position: relative;
        display: inline-block;
        width: 2em;
        height: 24px;

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: rgba($text-default, .87);
            transition-timing-function: cubic-bezier(.55, .055, .675, .19);
            transition-duration: 75ms;
            position: absolute;
            width: 24px;
            height: 3px;
            transition-property: transform;
            border-radius: 4px;
        }

        .hamburger-inner::before {
            top: -8px !important;
        }

        .hamburger-inner::after {
            bottom: -8px !important;
        }

        .hamburger-inner {
            top: 50%;
            display: block;
            margin-top: -2px;

            &::before,
            &::after {
                display: block;
                content: "";
                transition-timing-function: ease;
                transition-duration: .15s;
                background-color: rgba($text-default, .87);
            }

            &::before {
                top: -10px;
            }

            &::after {
                bottom: -10px;
            }
        }
    }

    &.is-active {
        .hamburger-box {
            .hamburger-inner {
                transition-delay: .12s;
                transition-timing-function: cubic-bezier(.215, .61, .355, 1);
                transform: rotate(45deg);

                &::before {
                    top: 0 !important;
                    transition: top 75ms ease, opacity 75ms ease .12s;
                    opacity: 0;
                }

                &::after {
                    bottom: 0 !important;
                    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

.text-upper {
    text-transform: uppercase;
}

.mat-mdc-tooltip {
    //white-space: pre-line;
}

.equal-space {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @media #{$medium-up} {
        flex-direction: row;
        justify-content: space-between;

        > * {
            flex: 1 1 auto;
            padding: 0 rem(8px);

            &:first-child {
                margin-left: rem(-8px);
            }

            &:last-child {
                margin-right: rem(-8px);
            }

            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-infix {
                width: auto;
            }
        }
    }
}

.ngx-toastr {
    width: 100% !important;
}

// .mat-expansion-panel-header .mat-expansion-indicator::after {
//     content: "+";
//     border: 0;
//     transform: none;
//   }

//   .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
//     content: "-";
//   }

.ngx-pagination {
    .current {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.privacy_prompt,
.privacy_prompt_middle {
    .privacy_prompt_content {
        table {
            tbody {
                tr {
                    &:not(.category_details) {
                        border-bottom: 1px solid $primary;
                    }
                }
            }
        }

        input[type="checkbox"]:not([disabled]) {
            &.toggle:checked {
                & + label {
                    background-color: $primary !important;
                }
            }
        }

        .privacy_prompt_buttons_explicit {
            .button_explicit {
                background-color: $primary !important;
                color: white;
            }

            .advanced_consent_button {
                border: solid 1px $primary;
                color: $primary;
            }

            .option_explicit {
                .radio-button {
                    .radio-button__control {
                        &::after {
                            background: $primary 0 0% no-repeat padding-box;
                        }
                    }
                }
            }
        }
    }

    .consent_preferences_buttons {
        .confirm_button {
            background-color: $primary !important;
        }
    }
}

.scrollable {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 4px solid transparent; /* Adds space inside the scrollbar track */
    }

    &::-webkit-scrollbar-thumb {
        background: $upsc-gray-3;
        border-radius: 8px;
        border: 4px solid transparent; /* Adds space inside the scrollbar thumb */
        background-clip: padding-box; /* Ensures the borders cut into the thumb */
    }
}

@media (min-width: 768px) {
    .implicit_privacy_prompt.implicit_consent {
        max-width: 900px !important; // This prevents the close button from being overlapped by the customer-support floating icon.
    }
}

.badge {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    border-radius: 3px;
    background-color: $primary;
    color: white;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.75;
    padding: 0 .5em;
    margin-left: .5em;
}

//.sm-button {
//    background-color: $primary !important;
//}

hr {
    margin: 0;
    border: none;
    border-bottom: solid 1px $ups-gray-4;
}

li, ol {
    &.no-space {
        margin: 0;
        padding: 0 1em;
    }

    &.no-bullet {
        list-style: none;
        padding: 0;
    }
}

.mark-required {
    &::after {
        content: "*";
        color: $ups-error;
        margin-left: 2px;
    }
}
