@import "typography";
@import "colors";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    color: $color-text-header;
    font-weight: 500;
}

h1 {
    font-size: em(25px);
}

h2 {
    font-size: em(20px);
}

h3 {
    font-size: em(18px);
}

h4 {
    font-size: em(16px);
}

h5 {
    font-size: em(14px);
}

h6 {
    font-size: em(13px);
}

h4,
h5,
h6 {
    margin: .5rem 0 .75rem;
}

p {
    font-family: $body-font-family;
    font-size: em(16px);
    font-weight: 500;
    line-height: em(24px);

    &.alternative {
        font-size: em(12px);
        line-height: em(20px);
    }
}

a:-webkit-any-link:not(.mdc-button) {
    color: $primary;
    text-decoration: underline;

    &:hover {
        color: $primary-dark;
    }

    &.secondary {
        color: $secondary;

        &:hover {
            color: $secondary-dark;
        }
    }

    &.plain {
        color: $text-default;
    }

    &.no-underline {
        text-decoration: none;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-underline {
    text-decoration: underline !important;
}

textarea {
    font-family: $body-font-family;
}

sup {
    line-height: 1;
}

.subtle {
    color: $ups-gray-3;
}

.ppi-page-title {
    position: relative;
    margin: 1rem 0 2rem;
    font-size: 1.5em;
    font-weight: 600;

    &::after {
        content: "";
        position: absolute;
        bottom: -.4em;
        left: 0;
        background-color: $primary;
        width: 3em;
        height: .15em;
    }
}

.underlined-title {
    position: relative;

    &::after {
        @extend %pseudo-underline-element;
        left: 0;
    }

    &--end {
        position: relative;

        &::after {
            @extend %pseudo-underline-element;
            right: 0;
        }
    }

    &--center {
        position: relative;

        &::after {
            @extend %pseudo-underline-element;
            left: calc(50% - 1.5em);
        }
    }
}

%pseudo-underline-element {
    content: "";
    position: absolute;
    bottom: -.4em;
    background-color: $primary;
    width: 3em;
    height: 4px;
}

.section-header {
    --header-underline-gap: .85rem;
    position: relative;
    padding-bottom: var(--header-underline-gap);

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 4.5rem;
        height: .25rem;
        background-color: $primary;
    }
}
