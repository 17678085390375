// Defining Breakpoints
$breakpoint-small: 21.5rem;       // equivalent to 344px
$breakpoint-mobile-large: 26.875rem;       // equivalent to 430px
//$breakpoint-small: 26.938rem;       // equivalent to 431px
$breakpoint-medium: 48rem;          // equivalent to 768px
$breakpoint-large: 64rem;           // equivalent to 1024px
$breakpoint-xlarge: 75rem;     // equivalent to 1200px

// Media Query Variables
$small-only: 'only screen and (max-width: #{$breakpoint-small})';
$medium-up: 'screen and (min-width: #{$breakpoint-medium})';
$medium-only: 'screen and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large})';
$medium-down: 'screen and (max-width: #{$breakpoint-medium})';
$large-up: 'screen and (min-width: #{$breakpoint-large})';
$large-only: 'screen and (min-width: #{$breakpoint-large}) and (max-width: #{$breakpoint-xlarge})';
$xlarge-up: 'screen and (min-width: #{$breakpoint-xlarge})';

$mobile: 'only screen and (max-width: #{$breakpoint-mobile-large})';
$large-mobile-up: 'only screen and (min-width: #{calc($breakpoint-mobile-large)}) and (max-width: #{$breakpoint-medium})';
$large-mobile-only: 'only screen and (min-width: #{calc($breakpoint-small + 1px)}) and (max-width: #{calc($breakpoint-mobile-large)})';

// UPS Laptop: 1920x1080 at 150% scaling ~ 1280x720.
$ups-laptop: 'screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem)';
