@import "media-queries";

$sidebar-collapsed-width: 60px;
$sidebar-width: 300px;
$sidebar-width-mobile: 75vw;

body {
    overflow-x: hidden;
}

/* Toggle Styles */

#wrapper {
    position: relative;
    padding-left: 0;
    transition: all 0.5s ease;

    #sidebar-wrapper {
        overflow: hidden;
        z-index: 9;
        position: fixed;
        height: 100%;
        overflow-y: auto;
        transition: all 0.5s ease;
    }

    #page-content-wrapper {
        min-width: 280px;
        padding-left: 0; // $sidebar-width;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        @media #{$medium-up} {
            padding-left: $sidebar-collapsed-width;
        }

        @media #{$large-up} {
            padding-left: $sidebar-collapsed-width; // $sidebar-width;
        }

        .header-logo {
            img {
                width: 20rem;
                max-width: 20rem;
                @media #{$small-only} {
                    width: 10rem;
                    max-width: 10rem;
                }
            }
        }

        transition: all 0.5s ease;
    }

    /* Toggle Menu | Menu Opened */
    &.toggled {
        #page-content-wrapper {
            position: relative;
            padding-left: 0;

            @media #{$medium-up} {
                padding-left: $sidebar-collapsed-width;
            }

            @media #{$large-up} {
                padding-left: $sidebar-width;
            }
        }
    }
}
