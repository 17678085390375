@mixin flex-center() {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin flex-column($align: stretch) {
    display: flex;
    flex-direction: column;
    align-items: $align;
    justify-content: flex-start;
}

@mixin flex-row($align: stretch) {
    display: flex;
    flex-direction: row;
    align-items: $align;
    justify-content: flex-start;
}

@mixin box-shadow-default() {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@mixin box-shadow-down-white() {
    box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12), 0 1px 2px rgba(255, 255, 255, 0.24);
}

@mixin box-shadow-right() {
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.12);
}

@mixin translate-y-hide($delay: .225s) {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
    z-index: -1;
    transition: all $delay ease-in-out 0s, visibility 0s linear $delay, z-index 0s linear 0.01s;
}

@mixin translate-y-show($delay: .225s) {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, $delay;
}

@mixin translate-x-hide($delay: .225s) {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-2em);
    z-index: -1;
    transition: all $delay ease-in-out 0s, visibility 0s linear $delay, z-index 0s linear 0.01s;
}

@mixin translate-x-show($delay: .225s) {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateX(0%);
    transition-delay: 0s, 0s, $delay;
}

@mixin collapsible-hide() {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform .225s cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin collapsible-show() {
    height: auto;
    transform: scaleY(1);
}

@mixin pseudo-arrow-base($color: currentColor) {
    font-family: "Material Icons";
    display: inline-block;
    position: absolute;
    top: 0;
    right: .5em;
    font-size: 1.25em;
    transform: translateZ(0);
    color: $color;
    opacity: .54;
}

@mixin pseudo-arrow-base-animation() {
    animation-duration: .3s, 1.5s;
    animation-delay: 0s, .3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
}

@mixin pseudo-arrow-down($color: currentColor) {
    @include pseudo-arrow-base($color);
    content: "\E313";
}

@mixin pseudo-arrow-down-animation() {
    @include pseudo-arrow-base-animation();
    animation-name: hvr-icon-hang-sink, hvr-icon-hang;
}

@mixin pseudo-arrow-left($color: currentColor) {
    @include pseudo-arrow-base($color);
    content: "\E314";
}

@mixin pseudo-arrow-right($color: currentColor) {
    @include pseudo-arrow-base($color);
    content: "\E315";
}

@mixin pseudo-arrow-up($color: currentColor) {
    @include pseudo-arrow-base($color);
    content: "\E316";
}

@mixin pseudo-arrow-up-animation() {
    @include pseudo-arrow-base-animation();
    animation-name: hvr-icon-bob-float, hvr-icon-bob;
}

@mixin change-img-teal-to-blue() {
    filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}

@mixin child-page-layout() {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    font-size: 1rem;
    max-width: 71.875rem;
    padding: 0 1rem;
    margin: 0 auto;
    background-color: white;
}
